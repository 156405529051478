body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    display: block;
    scroll-behavior: smooth;
    user-select: none;
  }

  html, #root {
    background-color: rgb(228, 228, 228);
  }

  
  body .offcanvas-backdrop.show {
    opacity: 0.8;
  }
  
  body .offcanvas-top {
    height: 100vh!important;
  }
  body .offcanvas {
    padding: 20px;
  }
  
  body .offcanvas, .header {
    background: rgb(239,73,49);
  }
  
  body .offcanvas .offcanvas-body a { 
    text-decoration: none;
  }
  body .offcanvas .offcanvas-body a h3 {
      padding-bottom: 20px;
      color: white;
      margin: 0;
      font-size: 30px; 
      text-align: center;
  }
  
  /* Main Font */
  
  * {
    font-family: 'Montserrat';
  }

  // Scrollbar

/* width */
::-webkit-scrollbar {
  width: 10px;
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(239,73,49); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 82, 68); 
}
